import React from 'react';

const LinkIcon: React.FC<{ className?: string }> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        stroke="currentColor"
    >
        <path
            d="M9 9L16.5 1.5V7.33333V1.5H10.6667"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.5 1.5H3.16667C2.24583 1.5 1.5 2.24583 1.5 3.16667V14.8333C1.5 15.7542 2.24583 16.5 3.16667 16.5H14.8333C15.7542 16.5 16.5 15.7542 16.5 14.8333V11.5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default LinkIcon;
