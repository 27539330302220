import type { IClientMicrocredit } from '@src/@types/clienttypes';

export interface RepaymentCondition {
    url?: string;
    title: string;
}

export const getRepaymentsConditions = (organization: IClientMicrocredit['organization']): RepaymentCondition[] => {
    const personalAccountSite = organization?.contacts?.personalAccountSite?.trim();
    const hasPersonalAccountSite = Boolean(personalAccountSite);
    const conditionsMap: { [key: string]: RepaymentCondition } = {};

    organization?.repaymentConditions?.forEach((condition) => {
        if (condition === 'Личный кабинет' && hasPersonalAccountSite) {
            conditionsMap[condition] = {
                title: condition,
                url: personalAccountSite,
            };
        } else {
            conditionsMap[condition] = { title: condition };
        }
    });

    if (hasPersonalAccountSite && !conditionsMap['Личный кабинет']) {
        conditionsMap['Личный кабинет'] = {
            title: 'Личный кабинет',
            url: personalAccountSite,
        };
    }

    return Object.values(conditionsMap);
};
