import type { ReactNode } from 'react';

import { Email, Odnoklassniki, Phone, VK } from '@sravni/react-icons';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import LinkIcon from '@src/components/icons/link';

type SupportType = 'phone' | 'website' | 'email' | 'odnoklassniki' | 'vkontakte';

export type SupportItem = {
    key: SupportType;
    link: string;
    label: string;
    icon: ReactNode;
};

const SOCIAL_NETWORK_ICONS_MAP: Record<SupportType, ReactNode> = {
    phone: <Phone />,
    email: <Email />,
    website: <LinkIcon />,
    odnoklassniki: <Odnoklassniki />,
    vkontakte: <VK />,
};

const SOCIAL_NETWORK_TYPE_MAP: Record<SupportType, string> = {
    phone: 'Номер телефона',
    email: 'Почта',
    website: 'Виджет на сайте',
    odnoklassniki: 'Одноклассники',
    vkontakte: 'ВКонтакте',
};

export const getSupportData = (contacts: IClientMicrocredit['organization']['contacts']): SupportItem[] => {
    const supportLinks: SupportItem[] = [];

    if (contacts.phone && Array.isArray(contacts.phone)) {
        const phoneLinks: SupportItem[] = contacts.phone.map((value) => ({
            key: 'phone',
            link: `tel:${value}`,
            label: value,
            icon: <Phone />,
        }));
        supportLinks.push(...phoneLinks);
    }

    if (contacts.email && Array.isArray(contacts.email)) {
        const phoneLinks: SupportItem[] = contacts.email.map((value) => ({
            key: 'email',
            link: `mailto:${value}`,
            label: value,
            icon: <Email />,
        }));
        supportLinks.push(...phoneLinks);
    }

    if (contacts.website) {
        supportLinks.push({
            key: 'website',
            link: contacts.website,
            label: SOCIAL_NETWORK_TYPE_MAP.website,
            icon: <LinkIcon />,
        });
    }

    if (contacts.socialsNetworks.length) {
        const excludedSocialNetworks = ['facebook', 'instagramm', 'twitter'];

        const socialLinks: SupportItem[] = contacts.socialsNetworks
            .filter((social) => !excludedSocialNetworks.includes(social.key))
            .map((social) => ({
                key: social.key as SupportType,
                link: social.link,
                label: SOCIAL_NETWORK_TYPE_MAP[social.key],
                icon: SOCIAL_NETWORK_ICONS_MAP[social.key],
            }));

        supportLinks.push(...socialLinks);
    }

    return supportLinks ?? [];
};
