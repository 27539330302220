import React from 'react';

import styles from '../../styles.module.scss';

interface Props {
    penalty: string;
}

export const Penalty = ({ penalty }: Props) => (
    <div className={styles.cardItem}>
        <div className={styles.title}>Начисление неустойки от суммы неуплаты</div>
        <div className={styles.text}>{penalty}</div>
    </div>
);
