import type { ReactNode } from 'react';
import { memo } from 'react';

import { Accordion } from '@sravni/react-design-system/lib/Accordion';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { sendOpenAccordionItemEvent } from '@src/helpers/analyticsEvents';

import { CustomerRequirements } from './components/CustomerRequirements';
import { DetailedConditions } from './components/DetailedConditions';
import { Documents } from './components/Documents';
import { GeneralInfo } from './components/GeneralInfo';
import { Penalty } from './components/Penalty';
import { Prolongation } from './components/Prolongation';
import { RepaymentsConditions } from './components/RepaymentsConditions';
import { Support } from './components/Support';
import { CARD_DETAILS_ACCORDION_DEFAULT_FIELDS_STATE } from './constants';
import styles from './styles.module.scss';
import type { AccordionDetailsFieldsState } from './types';
import { getRepaymentsConditions } from './utils/getRepaymentsConditions';
import { getSupportData } from './utils/getSupportData';

const Title = ({ title }: { title: string }) => <div className={styles.accordionTitle}>{title}</div>;

interface Props {
    card: IClientMicrocredit;
    fieldsState?: Partial<AccordionDetailsFieldsState>;
}

export const AccordionDetails = memo(({ card, fieldsState }: Props) => {
    const { organization, penalty, customerRequirements, name, advertising } = card;
    const repaymentsConditions = getRepaymentsConditions(organization);
    const support = getSupportData(organization?.contacts);
    const hasDocuments = organization?.documents?.length > 0;

    const source = `search|position_${advertising.position}`;
    const eventLabel = `${organization.name}|${name}|${advertising.offerId}|${source}`;

    const handleOpenAccordionItem = (eventActionValue: string, isExpandState: boolean) => {
        if (isExpandState) {
            sendOpenAccordionItemEvent(eventLabel, eventActionValue);
        }
    };

    const renderAccordionItem = (title: string, children: ReactNode) => (
        <Accordion.Item
            onChange={(isExpandState) => handleOpenAccordionItem(title, isExpandState)}
            className={styles.accordionItem}
            title={<Title title={title} />}
            unmountOnExit={false}
        >
            {children}
        </Accordion.Item>
    );

    const visibleFieldsState = {
        ...CARD_DETAILS_ACCORDION_DEFAULT_FIELDS_STATE,
        ...fieldsState,
    };

    return (
        <Accordion className={styles.accordion} padding={false}>
            {visibleFieldsState.conditions &&
                renderAccordionItem('Подробные условия', <DetailedConditions card={card} />)}

            {visibleFieldsState.prolongation &&
                renderAccordionItem('Продление займа', <Prolongation prolongation={card.prolongation} />)}

            {visibleFieldsState.penalty &&
                penalty?.length > 0 &&
                renderAccordionItem('Штрафы за просрочку', <Penalty penalty={penalty} />)}

            {visibleFieldsState.repaymentsConditions &&
                repaymentsConditions.length > 0 &&
                renderAccordionItem(
                    'Способы погашения',
                    <RepaymentsConditions eventLabel={eventLabel} repaymentsConditions={repaymentsConditions} />,
                )}
            {visibleFieldsState.support &&
                support.length > 0 &&
                renderAccordionItem('Поддержка', <Support support={support} eventLabel={eventLabel} />)}

            {visibleFieldsState.customerRequirements &&
                customerRequirements.age &&
                renderAccordionItem(
                    'Требования к заемщику и документы',
                    <CustomerRequirements customerRequirements={customerRequirements} />,
                )}

            {visibleFieldsState.generalInfo &&
                organization &&
                renderAccordionItem(
                    `Общая информация о ${organization.name}`,
                    <GeneralInfo eventLabel={eventLabel} organization={organization} />,
                )}

            {visibleFieldsState.documents &&
                hasDocuments &&
                renderAccordionItem(
                    'Документы компании',
                    <Documents eventLabel={eventLabel} documents={organization?.documents} />,
                )}
        </Accordion>
    );
});
