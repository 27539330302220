import type { FC, SyntheticEvent } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Button } from '@sravni/react-design-system/lib/Button';
import type { DialogProps } from '@sravni/react-design-system/lib/Dialog';
import { Dialog } from '@sravni/react-design-system/lib/Dialog';
import { Sheet } from '@sravni/react-design-system/lib/Sheet';
import { useIsMobile } from '@sravni/react-utils';

import styles from './styles.module.scss';

interface CurtainPopupProps extends DialogProps {
    title: string;
    buttonText?: string;
    onComplete: (event: SyntheticEvent) => void;
}

const { Heading } = Typography;

export const CurtainPopup: FC<CurtainPopupProps> = (props) => {
    const isMobile = useIsMobile();
    const { children, onClose, buttonText = 'Понятно', title, onComplete, ...restProps } = props;

    const Component = isMobile ? Sheet : Dialog;

    return (
        <Component onClose={onClose} {...restProps} className={styles.root} closable={false}>
            <Component.Header title={<Heading level={4}>{title}</Heading>} />
            <Component.Content className={styles.container}>{children}</Component.Content>
            <div className={styles.footer}>
                <Button onClick={onComplete} size={60} variant="primary" block>
                    {buttonText}
                </Button>
            </div>
        </Component>
    );
};
