import { Icon, Typography } from '@sravni/react-design-system';
import { Verification } from '@sravni/react-icons';

import styles from '@src/components/ProductList/components/CardV2/components/AdditionalConditions/styles.module.scss';

const { Text } = Typography;

export const NoAdditionalConditions = () => (
    <div className={styles.root}>
        <div className={styles.iconWrapper}>
            <Icon className="h-color-B100" icon={<Verification />} size={24} />
        </div>
        <Text size={14}>В МФО нет дополнительных условий и скрытых комиссий</Text>
    </div>
);
