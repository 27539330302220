import type { SyntheticEvent } from 'react';

import { Button } from '@sravni/react-design-system/lib/Button';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { sendOpenReviewsEvent } from '@src/helpers/analyticsEvents';

import styles from './styles.module.scss';

type Props = {
    text: string;
    link: string;
    name: string;
    organizationName: string;
    advertising: IClientMicrocredit['advertising'];
    className?: string;
};

export const RedirectReviewButton = ({ text, link, organizationName, name, advertising, className }: Props) => {
    const handleReviewsClick = (event: SyntheticEvent) => {
        event.stopPropagation();

        const source = `search|position_${advertising.position}`;
        const eventLabel = `${organizationName}|${name}|${advertising.offerId}|${source}`;

        const eventActionType = 'Оставить';
        sendOpenReviewsEvent(eventLabel, eventActionType);
    };

    return (
        <div className={className}>
            <a onClick={handleReviewsClick} className={styles.link} href={link} target="_blank" rel="noreferrer">
                <Button size={52} variant="outlined" block>
                    {text}
                </Button>
            </a>
        </div>
    );
};
