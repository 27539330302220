import cn from 'classnames';
import React, { useMemo } from 'react';

import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Tooltip } from '@sravni/react-icons';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import {
    calculateOverpayment,
    translateRepaymentCondition,
} from '@src/components/details/components/DetailsCard/utils';
import { ApprovalProbability } from '@src/components/ProductList/components/CardV2/components/ApprovalProbability';
import { getAdditionalConditionsCardText } from '@src/components/ProductList/components/CardV2/utils/getAdditionalConditionsCardText';
import { getAmountString } from '@src/helpers/common';
import { getDaysPluralize } from '@src/helpers/strings';
import { useCard } from '@src/reducers/product';

import styles from './styles.module.scss';

type Props = {
    amount: number;
    term: number;
    card: IClientMicrocredit;
    rateMax: number;
    rateRange: string;
    isRateRange: boolean;
    onAdditionalConditionsTooltipClick: (event: React.SyntheticEvent) => void;
    onRepaymentConditionsTooltipClick: (event: React.SyntheticEvent) => void;
};

export const Content = ({
    amount,
    term,
    card,
    rateMax,
    rateRange,
    isRateRange,
    onAdditionalConditionsTooltipClick,
    onRepaymentConditionsTooltipClick,
}: Props) => {
    const { repaymentConditions, approvalProbability, additionalServices } = card;
    const amountText = getAmountString(amount, Currency.RUB);
    const pluralizeTerm = getDaysPluralize(term);
    const termText = `${term} ${pluralizeTerm}`;
    const { overpaymentText } = calculateOverpayment(rateMax, term, amount);
    const additionalConditionsBadge = getAdditionalConditionsCardText(additionalServices);
    const isEarlyRepayment = repaymentConditions?.isEarlyRepayment ?? false;
    const repayment = translateRepaymentCondition(repaymentConditions);

    const data = useMemo(
        () =>
            [
                { title: 'Сумма займа (на карту)', value: amountText },
                { title: 'Ставка по займу в день', value: `${rateRange} %` },
                { title: 'Срок', value: termText },
                {
                    title: isRateRange ? 'Макс. переплата за весь срок' : 'Переплата за весь срок',
                    value: overpaymentText,
                },
            ].filter((item) => Boolean(item.value)),
        [amountText, isRateRange, overpaymentText, rateRange, termText],
    );

    const hasRepaymentConditionsComment =
        isEarlyRepayment && repaymentConditions?.repayment === 'paid' && Boolean(repaymentConditions?.comment);

    return (
        <div className={styles.cardContainer}>
            {data.map(({ title, value }) => (
                <div key={title} className={styles.cardItem}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: value }} />
                </div>
            ))}
            <hr className={styles.divider} />

            <div className={styles.cardItemWithIcon}>
                <div className={styles.subItem}>
                    <div className={styles.title}>Доп. услуги</div>
                    <div className={cn(styles.text, styles.textDesktop)}>{additionalConditionsBadge}</div>
                </div>
                <div>
                    <Icon
                        className={styles.valueIcon}
                        size={20}
                        icon={<Tooltip onClick={onAdditionalConditionsTooltipClick} />}
                    />
                    <div className={cn(styles.text, styles.textMobile)}>{additionalConditionsBadge}</div>
                </div>
            </div>

            <div className={styles.cardItem}>
                <div className={styles.title}>Досрочное погашение</div>
                <div className={styles.text}>{isEarlyRepayment ? 'Да' : 'Нет'}</div>
            </div>

            <div className={styles.cardItemWithIcon}>
                <div className={styles.subItem}>
                    <div className={styles.title}>Погашение</div>
                    <div className={cn(styles.text, styles.textDesktop)}>{repayment}</div>
                </div>
                <div>
                    {hasRepaymentConditionsComment && (
                        <Icon
                            className={styles.valueIcon}
                            size={20}
                            icon={<Tooltip onClick={onRepaymentConditionsTooltipClick} />}
                        />
                    )}
                    <div className={cn(styles.text, styles.textMobile)}>{repayment}</div>
                </div>
            </div>

            <div className={styles.cardItem}>
                <div className={styles.title}>Вероятность одобрения</div>
                <div className={styles.text}>
                    <ApprovalProbability category={approvalProbability?.category} />
                </div>
            </div>

            <hr className={styles.divider} />
        </div>
    );
};
