import React, { useMemo } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Star } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import type { IOrganization } from '@src/@types/microcredits';
import { calculateOverpayment } from '@src/components/details/components/DetailsCard/utils';
import { ReviewsLink } from '@src/components/details/components/ReviewsLink';
import { getReviewsCount } from '@src/components/details/helpers';
import { Logo } from '@src/components/Logotype';
import { getAmountString } from '@src/helpers/common';
import { getOrganizationPageLink } from '@src/helpers/links';

import styles from './styles.module.scss';

const { Heading, Text } = Typography;

type Props = {
    amount: number;
    term: number;
    rateMax: number;
    isRateRange: boolean;
    organization: IOrganization;
    cardName: string;
    isBankPartner: boolean;
    advertising: IClientMicrocredit['advertising'];
};

export const Header = ({
    term,
    amount,
    rateMax,
    isRateRange,
    organization,
    cardName,
    isBankPartner,
    advertising,
}: Props) => {
    const isMobile = useIsMobile();
    const { name, logotypes, alias, rating, reviewsSeoStats } = organization || {};

    const logoLink = { href: alias && getOrganizationPageLink(alias), withAdvertising: false };
    const reviewsCount = useMemo(() => getReviewsCount(reviewsSeoStats?.reviewsCount), [reviewsSeoStats?.reviewsCount]);

    const { overpayment } = calculateOverpayment(rateMax, term, amount);
    const amountRefund = getAmountString(overpayment + amount, Currency.RUB);

    return (
        <div className={styles.title}>
            <div className={styles.organization}>
                <Logo
                    link={logoLink}
                    logo={logotypes?.android}
                    isPartner={isBankPartner || advertising?.isPartner}
                    size={44}
                />
                <div>
                    <Heading level={4} as="div">
                        {name}
                    </Heading>

                    <div className={styles.ratingBlock}>
                        {rating && (
                            <div className={styles.rating}>
                                <Icon className={styles.star} size={16} color="orange" icon={<Star />} />
                                <span>{rating.toFixed(2)}</span>
                            </div>
                        )}

                        {reviewsSeoStats && !isMobile && (
                            <ReviewsLink
                                reviewsCount={reviewsCount}
                                href={`${logoLink.href}otzyvy/`}
                                organizationName={name}
                                name={cardName}
                                advertising={advertising}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.amountWrapper}>
                <Text className="h-color-D60" size={12}>
                    {isRateRange ? 'Макс. сумма к возврату' : 'Сумма к возврату'}
                </Text>
                <Heading level={4} as="div">
                    {amountRefund}
                </Heading>
            </div>
        </div>
    );
};
