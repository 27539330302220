import React, { useMemo } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Star } from '@sravni/react-icons';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { ReviewsLink } from '@src/components/details/components/ReviewsLink';
import { getOrganizationPageLink } from '@src/helpers/links';

import { getReviewsCount } from '../../helpers';

import styles from './styles.module.scss';

const { Text } = Typography;

interface Props {
    card: IClientMicrocredit;
}

export const OrganizationRatingBlock = ({ card }: Props) => {
    const { rating, reviewsSeoStats, alias } = card.organization || {};

    const logoLink = { href: alias && getOrganizationPageLink(alias), withAdvertising: false };
    const reviewsCount = useMemo(() => getReviewsCount(reviewsSeoStats?.reviewsCount), [reviewsSeoStats?.reviewsCount]);

    if (!rating || !reviewsCount) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            {rating && (
                <div className={styles.ratingBlock}>
                    <Text size={12} className="h-color-D60">
                        Рейтинг организации
                    </Text>
                    <div className={styles.rating}>
                        <Text size={14} className="h-color-D100" strong>
                            {rating.toFixed(2)}
                        </Text>
                        <Icon className={styles.star} size={16} color="orange" icon={<Star />} />
                    </div>
                </div>
            )}
            {reviewsSeoStats && (
                <ReviewsLink
                    href={`${logoLink.href}otzyvy/`}
                    reviewsCount={reviewsCount}
                    organizationName={card.organization.name}
                    advertising={card.advertising}
                    name={card.name}
                />
            )}
        </div>
    );
};
