import React, { memo } from 'react';

import type { IClientMicrocredit } from '@src/@types/clienttypes';

import styles from '../../styles.module.scss';

interface Props {
    prolongation: IClientMicrocredit['prolongation'];
}

export const Prolongation = memo(({ prolongation }: Props) => {
    const hasProlongation = prolongation.isAllowed && Boolean(prolongation.information);
    const prolongationInformation = hasProlongation
        ? prolongation.information
        : 'В данном МФО отсутствует возможность продления займа';

    return (
        <div className={styles.cardItem}>
            <div className={styles.title}>{hasProlongation ? 'Да' : 'Нет'}</div>
            <div className={styles.text}>{prolongationInformation}</div>
        </div>
    );
});

Prolongation.displayName = 'Prolongation';
