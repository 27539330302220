import { Icon, Typography } from '@sravni/react-design-system';
import { Warning } from '@sravni/react-icons';

import styles from '@src/components/ProductList/components/CardV2/components/AdditionalConditions/styles.module.scss';

const { Text } = Typography;

export const AdditionalServiceUnknown = () => (
    <div className={styles.root}>
        <div className={styles.iconWrapper}>
            <Icon className="h-color-R100" icon={<Warning />} size={24} />
        </div>
        <Text size={14}>
            Возможно наличие доп. услуг.
            <br />
            При получении займа в этом МФО, внимательно изучайте условия и договор
        </Text>
    </div>
);
