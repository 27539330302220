import cn from 'classnames';
import type { SyntheticEvent } from 'react';
import React, { useMemo } from 'react';

import { Typography } from '@sravni/react-design-system';

import type { IOrganization } from '@src/@types/microcredits';
import { sendGeneralInfoItemClickEvent } from '@src/helpers/analyticsEvents';

import styles from '../../styles.module.scss';

type Props = {
    organization: IOrganization;
    eventLabel: string;
};

export const GeneralInfo = ({ eventLabel, organization }: Props) => {
    const registrationDate = `${new Date(organization.registrationDate).getFullYear()} год`;
    const license = organization.license === '0' ? '' : organization.license;

    const firstRowData = useMemo(
        () =>
            [
                { title: 'Полное наименование', value: organization.fullName },
                { title: 'Год основания', value: registrationDate },
                { title: 'Региональная сеть', value: organization.regionalNetwork },
                { title: 'Лицензия', value: `№ ${license}` },
            ].filter((item) => Boolean(item.value)),
        [license, organization.fullName, organization.regionalNetwork, registrationDate],
    );

    const handleItemClick = (event: SyntheticEvent, eventActionValue: string) => {
        event.stopPropagation();

        sendGeneralInfoItemClickEvent(eventLabel, eventActionValue);
    };

    return (
        <div className={cn(styles.twoColumnsWrapper, styles.oneRow)}>
            {firstRowData.map(({ title, value }) => (
                <div key={title} className={styles.cardItem}>
                    <Typography.Text size={12} className="h-color-D60">
                        {title}
                    </Typography.Text>
                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: value }} />
                </div>
            ))}

            {organization.contacts.phone.length && (
                <div className={styles.cardItem}>
                    <Typography.Text size={12} className="h-color-D60">
                        {organization.contacts.phone.length > 1 ? 'Телефоны' : 'Телефон'}
                    </Typography.Text>
                    {organization.contacts.phone.map((phone) => (
                        <div key={phone} className={styles.text}>
                            {phone}
                        </div>
                    ))}
                </div>
            )}

            {organization.contacts.address && (
                <div className={styles.cardItem}>
                    <Typography.Text size={12} className="h-color-D60">
                        Головной офис
                    </Typography.Text>
                    <div className={styles.text}>{organization.contacts.address}</div>
                </div>
            )}

            {organization.contacts.website && (
                <div className={styles.cardItem}>
                    <Typography.Text size={12} className="h-color-D60">
                        Официальный сайт
                    </Typography.Text>
                    <a
                        onClick={(event) => handleItemClick(event, 'Официальный сайт')}
                        target="_blank"
                        href={organization.contacts.website}
                        rel="noreferrer"
                    >
                        {organization.contacts.website}
                    </a>
                </div>
            )}
        </div>
    );
};
