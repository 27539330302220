import cn from 'classnames';
import type { SyntheticEvent } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Icon } from '@sravni/react-design-system/lib/Icon';

import LinkIcon from '@src/components/icons/link';
import { sendRepaymentConditionsItemClickEvent } from '@src/helpers/analyticsEvents';

import styles from '../../styles.module.scss';
import type { RepaymentCondition } from '../../utils/getRepaymentsConditions';

type Props = {
    repaymentsConditions: RepaymentCondition[];
    eventLabel: string;
};

const { Link, Text } = Typography;

export const RepaymentsConditions = ({ repaymentsConditions, eventLabel }: Props) => {
    const handleIconClick = (event: SyntheticEvent, eventActionValue: string) => {
        event.stopPropagation();

        sendRepaymentConditionsItemClickEvent(eventLabel, eventActionValue);
    };

    return (
        <div className={styles.threeColumnsWrapper}>
            {repaymentsConditions.map(({ url, title }) => (
                <div key={title} className={styles.cardItemWithIcon}>
                    <div className={styles.content}>
                        <Text className={cn(styles.iconLabel, 'h-color-D60')} size={12}>
                            {title}
                        </Text>
                        {url && (
                            <Link
                                onClick={(event) => handleIconClick(event, title)}
                                target="_blank"
                                rel="noopener"
                                className={styles.link}
                                href={url}
                            >
                                <Icon icon={<LinkIcon />} size={20} />
                            </Link>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
