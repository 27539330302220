import { useMemo } from 'react';

import type { IRate } from '@src/@types/microcredits';
import type { ITermRange } from '@src/@types/ranges';
import { TERMS } from '@src/@types/ranges';
import { getRateRangeString } from '@src/helpers/common';

export const useCardData = (rubRates: Array<Omit<IRate, '__v' | 'product' | 'isProgressive'>> = []) =>
    useMemo(() => {
        const rateMin = Math.min(...rubRates.flatMap(({ periods }) => periods.map(({ rate }) => rate.from ?? 0)));
        const rateMax = Math.max(...rubRates.flatMap(({ periods }) => periods.map(({ rate }) => rate.to ?? 0)));
        const rateRange = getRateRangeString({ from: rateMin, to: rateMax }, true, false, true);
        const isRateRange = rateMin !== rateMax;

        const amountMin = Math.min(...rubRates.map(({ amount }) => amount.from ?? 0));
        const amountMax = Math.max(...rubRates.map(({ amount }) => amount.to ?? 0));

        const termMin = rubRates
            .flatMap(({ periods }) =>
                periods.map(({ term: { fromValue, fromUnit, from } }) => ({
                    from: from ?? 'P0D',
                    fromUnit,
                    fromValue: fromValue ?? 0,
                })),
            )
            .reduce((min, value) => (min.fromValue > value.fromValue ? value : min), {
                fromValue: Infinity,
                from: 'P0D',
                fromUnit: TERMS.days,
            });

        const termMax = rubRates
            .flatMap(({ periods }) =>
                periods.map(({ term: { toValue, toUnit, to } }) => ({
                    to: to ?? 'P0D',
                    toUnit,
                    toValue: toValue ?? 0,
                })),
            )
            .reduce((max, value) => (max.toValue < value.toValue ? value : max), {
                toValue: 0,
                to: 'P0D',
                toUnit: TERMS.days,
            });

        const term = { ...termMin, ...termMax } as ITermRange;

        return { amountMax, amountMin, term, rateMin, rateMax, rateRange, isRateRange };
    }, [rubRates]);
