import { Icon, Typography } from '@sravni/react-design-system';
import { Warning } from '@sravni/react-icons';

import styles from '@src/components/ProductList/components/CardV2/components/AdditionalConditions/styles.module.scss';

const { Text } = Typography;

type Props = {
    text: string;
};

export const RepaymentConditions = ({ text }: Props) => (
    <div className={styles.root}>
        <div className={styles.iconWrapper}>
            <Icon className="h-color-R100" icon={<Warning />} size={24} />
        </div>
        <Text size={14}>{text}</Text>
    </div>
);
