import cn from 'classnames';
import React from 'react';

import { Typography } from '@sravni/react-design-system';

import type { ICustomerRequirements } from '@src/@types/microcredits';
import { useDictionariesFieldsMap } from '@src/reducers/dictionaries';
import { getDocumentsText } from '@src/utils/texts';

import styles from '../../styles.module.scss';
import { getRequirementsAge } from '../../utils/getRequirementsAge';

interface Props {
    customerRequirements: ICustomerRequirements;
}

export const CustomerRequirements = ({ customerRequirements }: Props) => {
    const { demands: demandsDictionary, microCreditsDocuments } = useDictionariesFieldsMap();
    const age = getRequirementsAge(customerRequirements);

    const documents =
        getDocumentsText(
            microCreditsDocuments,
            customerRequirements?.documents.filter(({ required }) => required),
        ) ?? [];

    const demands: string[] = customerRequirements.demands.map((demand) => demandsDictionary[demand]) ?? [];

    const items = [...demands, ...documents];

    return (
        <div className={cn(styles.twoColumnsWrapper, styles.oneRow)}>
            <div className={styles.cardItem}>
                <Typography.Text className="h-color-D60" size={12}>
                    Возраст
                </Typography.Text>
                <div className={styles.text}>{age}</div>
            </div>
            {items.length > 0 && (
                <div className={styles.cardItem}>
                    <div className={styles.title}>Требования и документы</div>
                    <div className={styles.text}>
                        <ul className={styles.list}>
                            {items.map((item) => (
                                <li key={item}>
                                    <div className={styles.text}>{item}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
