import cn from 'classnames';
import React, { useMemo } from 'react';

import { Typography } from '@sravni/react-design-system';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import { timeRange } from '@src/helpers/common';
import { printDictionaryFieldsMap } from '@src/helpers/dictionaries';
import { useCardData } from '@src/hooks/useCardData';
import { useDictionariesFieldsMap } from '@src/reducers/dictionaries';

import styles from '../../styles.module.scss';

import { getAmount, getRatePsk, getTerm } from './utils';

interface Props {
    card: IClientMicrocredit;
}

export const DetailedConditions = ({ card }: Props) => {
    const dictionaries = useDictionariesFieldsMap();
    const { amountMin, amountMax, term, rateMin } = useCardData(card?.rates?.[Currency.RUB]);

    const amount = getAmount(amountMin, amountMax);
    const termValue = getTerm(term);

    const receiveTime = card.receiveTime && timeRange(card.receiveTime);
    const considerationTime = card.considerationTime && timeRange(card.considerationTime);
    const registrationWay = printDictionaryFieldsMap(dictionaries.registrationWay, card.registrationWay, ', ');

    const ratePsk = getRatePsk(card);

    const rateMinValue = rateMin !== undefined ? `${rateMin}% в день` : '';

    const firstRowData = useMemo(
        () =>
            [
                { title: 'Сумма займа', value: amount },
                { title: 'Срок займа', value: termValue },
            ].filter((item) => Boolean(item.value)),
        [amount, termValue],
    );

    const secondRowData = useMemo(
        () => [{ value: ratePsk }, { value: rateMinValue }].filter((item) => Boolean(item.value)),
        [rateMinValue, ratePsk],
    );

    const thirdRowData = useMemo(
        () =>
            [
                { title: 'Время получения займа', value: receiveTime },
                { title: 'Время рассмотрения заявки', value: considerationTime },
            ].filter((item) => Boolean(item.value)),
        [considerationTime, receiveTime],
    );

    return (
        <div className={styles.twoColumnsWrapper}>
            {firstRowData.map(({ title, value }) => (
                <div className={styles.cardItem} key={title}>
                    <Typography.Text size={12} className="h-color-D60">
                        {title}
                    </Typography.Text>
                    <div
                        className={cn(styles.text, {
                            [styles.wrapedText]: title === 'Сумма займа',
                        })}
                        dangerouslySetInnerHTML={{ __html: value }}
                    />
                </div>
            ))}

            {secondRowData.length > 0 && (
                <div className={styles.cardItem}>
                    <Typography.Text size={12} className="h-color-D60">
                        Минимальная ставка займа
                    </Typography.Text>
                    {secondRowData.map(({ value }) => (
                        <div key={value} className={styles.text}>
                            {value}
                        </div>
                    ))}
                </div>
            )}

            {thirdRowData.map(({ title, value }) => (
                <div key={title} className={styles.cardItem}>
                    <Typography.Text size={12} className="h-color-D60">
                        {title}
                    </Typography.Text>
                    <div className={styles.text} dangerouslySetInnerHTML={{ __html: value }} />
                </div>
            ))}

            {Boolean(registrationWay.length) && (
                <div className={styles.cardItem}>
                    <Typography.Text size={12} className="h-color-D60">
                        Оформление
                    </Typography.Text>
                    <div className={styles.text}>{registrationWay}</div>
                </div>
            )}
        </div>
    );
};
