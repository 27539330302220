import upperFirst from 'lodash/upperFirst';

import { Icon, Typography } from '@sravni/react-design-system';
import pluralize from '@sravni/utils/lib/pluralize';

import type { AdditionalServicesList, AdditionalServicesType } from '@src/@types/microcredits';
import { getServiceIcon } from '@src/components/ProductList/components/CardV2/components/AdditionalConditions/components/AdditionalService/helpers/getServiceIcon';

import styles from '../../styles.module.scss';

type Props = {
    service: AdditionalServicesList;
};

const SERVICE_TYPE_MAP: Record<AdditionalServicesType, string> = {
    insurance: 'страхование жизни',
    fee: 'комиссия при погашении',
    other: 'другое',
    informing: 'информирование',
    prolongation: 'пролонгация',
    'legal services': 'юридические услуги',
};

const { Text } = Typography;

export const AdditionalService = ({ service }: Props) => {
    const { price, type, name } = service;

    const getTitleText = () => {
        const serviceTypeText = SERVICE_TYPE_MAP[type];

        const priceValue = String(price);

        if (!priceValue.length) {
            return upperFirst(serviceTypeText);
        }

        if (priceValue.includes('%')) {
            return `${priceValue} ${serviceTypeText}`;
        }

        const pluralizeText = pluralize(Number(priceValue), 'рубль', 'рубля', 'рублей');

        return `${priceValue} ${pluralizeText} ${serviceTypeText}`;
    };

    const serviceIcon = getServiceIcon(type);
    const titleText = getTitleText();

    return (
        <div className={styles.root}>
            <div className={styles.iconWrapper}>
                <Icon className="h-color-R500" icon={serviceIcon} size={24} />
            </div>
            <div>
                <Text className={styles.label} size={14}>
                    {titleText}
                </Text>
                <Text className={styles.subtitle} size={12}>
                    {name}
                </Text>
            </div>
        </div>
    );
};
