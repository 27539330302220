import pluralize from '@sravni/utils/lib/pluralize';

import type { ICustomerRequirements } from '@src/@types/microcredits';

export const getRequirementsAge = (requirements: ICustomerRequirements): string => {
    const { age: startAge, femaleAgeAtRepayment, manAgeAtRepayment } = requirements;
    const endAge = Math.min(femaleAgeAtRepayment, manAgeAtRepayment);
    const pluralizeStartAge = pluralize(startAge, 'года', 'лет', 'лет');
    const pluralizedEndAge = pluralize(endAge, 'года', 'лет', 'лет');

    if (startAge === endAge) {
        return `${startAge} ${pluralizeStartAge}`;
    }

    if (endAge === 0) {
        return `от ${startAge} ${pluralizeStartAge} без ограничений`;
    }

    return `от ${startAge} до ${endAge} ${pluralizedEndAge}`;
};
